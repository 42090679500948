@import "src/styles/mixins";
@import "src/styles/shortcuts";

.bannerSpinner {
  min-height: 442px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
}

.bonusBlock {
  min-height: 412px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
}

.interestingBlock {
  min-height: 496px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
}

.waitingBlock {
  min-height: 516px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
}

// .Temporary {
//   background-image: url("../../../new-images/temporaryBannerDesktop.svg");
//   background-repeat: no-repeat;
//   padding: 85px 70px 50px;
//   margin: 45px 0 0;
//   &__title {
//     font-family: "Inter", sans-serif;
//     font-style: normal;
//     font-weight: 900;
//     font-size: 64px;
//     line-height: 72px;
//     color: #131416;
//     margin: 0 0 15px;
//   }
//   &__subtitle {
//     font-family: "Inter", sans-serif;
//     font-style: normal;
//     font-weight: 900;
//     font-size: 40px;
//     line-height: 72px;
//     color: #131416;
//     margin: 0 0 68px;
//   }
//   &__subtitleSpan {
//     color: #f73232;
//   }
//   &__button {
//     font-family: "Inter", sans-serif;
//     font-style: normal;
//     font-weight: 700;
//     font-size: 26px;
//     line-height: 20px;
//     text-align: center;
//     letter-spacing: 0.04em;
//     color: #ffffff;
//     background: #f73232;
//     border-radius: 4px;
//     padding: 25px 38px;
//     border: none;
//     margin: 0;
//     transition: 0.2s ease-in-out;
//   }
//   &__button:hover {
//     background: $Red_one;
//   }
// }

// @media (min-width: 1920px) {
//   .Temporary {
//     background-image: url("../../../new-images/tamporaryBannerLargeDesktop.svg");
//   }
// }

// @media (min-width: 1200px) and (max-width: 1439px) {
//   .Temporary {
//     background-image: url("../../../new-images/temporaryBannerLaptop.svg");
//     &__title {
//       font-size: 56px;
//       line-height: 72px;
//     }
//   }
// }

// @media (min-width: 768px) and (max-width: 1199px) {
//   .Temporary {
//     background-image: url("../../../new-images/temporaryBannerTablet.svg");
//     padding: 32px 16px;
//     &__title {
//       font-size: 56px;
//       line-height: 72px;
//     }
//     &__subtitle {
//       margin: 0 0 121px;
//     }
//   }
// }

// @media (max-width: 767px) {
//   .Temporary {
//     background-image: url("../../../new-images/temporaryBannerMobile.svg");
//     padding: 40px 20px 100px;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     &__title {
//       text-align: center;
//       font-size: 36px;
//       line-height: 40px;
//       margin: 0 0 25px;
//       max-width: 230px;
//     }
//     &__subtitle {
//       font-size: 20px;
//       line-height: 24px;
//       margin: 0 0 62px;
//     }
//   }
// }
