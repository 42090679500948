@import "./src/styles/mixins";
@import "./src/styles/fonts";

.UserBloodType {
  padding: 4px 8px;
  background: $White;
  box-shadow: 0 4px 20px rgba(19, 20, 22, 0.08);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 85px;

  & > span {
    @extend .Caption_2;
    white-space: nowrap;
  }
}

.FakeUserBloodType {
  padding: 4px 8px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 85px;
  border: none;

  & > span {
    @extend .Caption_2;
    white-space: nowrap;
  }
}
